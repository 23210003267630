body {
  background-color: rgb(255, 255, 255);
  font-family: 'metropolis';
  color: #222222;
}
html {
  margin:0;
  padding:0;
  height:100%;
}

*:hover,
*:focus,
*:active
{
  outline: none;
  box-shadow: none !important;
  -webkit-appearance: none;
}


select option:not(:checked) { background: #fff; }

select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

@font-face /*perintah untuk memanggil font eksternal*/
{
    font-family: 'metropolis'; /*memberikan nama bebas untuk font*/
    src: url('./metropolis.regular.otf');/*memanggil file font eksternalnya di folder nexa*/
}

/* HOME */

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.btn-navbar-right {
  margin-top: -10px !important;
  margin-bottom: -8px !important;
  margin-right: -18px !important;
  height: 70px;
  border-radius: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: #071C4D;
}

.navbar-light .navbar-nav .nav-link.active {
  font-weight: bold;
  color: #071C4D !important;
}

.footer-text{
  font-size: 1.1rem !important;
}

.footer-link{
  font-size: 1.1rem !important;
  font-weight: bold !important;
}

.bg-success{
  background-color: #32c33b !important;
}

.btn-login {
  background-color: #071C4D;
  color: #fff;
}

.btn-login:hover {
  color: #fff;
}

.navbar{
  background-color: #fff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.caption{
  font-weight: 700;
  font-size: 5.5rem;
  line-height: 40px;
  border: #9B9B9B;
}
.icon-cart{
  margin-right: 10px;
}
.title-icon{
  margin-left: 10px;
  margin-top: 7px;
}
.icon{
  width: 30px;
}
.slide{
  margin-top: 40px;
}
.sub-category{
  color: #9B9B9B;
}
.card-1{
  background-color:#CC0B04;
}
.card-2{
background-color: #1C3391;
}
.card-3{
  background-color: #F67B02;
}
.card-4{
background-color: #E31F51;
}
.card-5{
background-color: #57CD9E;
}
.categories img{
  width: 132px;
  height: 132px;
}
.font-category{
  font-size: 30px;
  font-weight: 700;
}
.card-title, a{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  text-decoration: none;
}
.card-title, a:hover{
  color: #222222;
}
.price{
font-size: 16px;
line-height: 16px;
color: #050201;
font-weight: bold;
}
.merk{
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color:#9B9B9B;
}
.rating{
  font-weight: 400;
font-size: 10px;
line-height: 8px;
}
.button-login, .button-signup{
  border-radius: 24px;
  margin: 5px 3px 5px 3px;
  
}
.button-login{
  background-color: #03ac0e;
  color: #fff;
  padding-left: 23px;
  padding-right: 23px;
}
.navbar-nav{
  margin-left: auto;
}
.search-input{
  border-radius: 50px;
  border-right: 0px solid rgb(255, 255, 255);
}
.search{
  border-radius: 50px;
  background-color: #fff !important;
}
.filter{
  margin-left: 5px;
  border: 0.2px solid #9d9e9e;
  border-radius: 12px !important;
}
.filter:hover{
  border: 1px solid #393b41;
  background-color: aliceblue;
}
.carousel-inner{
  border-radius: 8px;
}
.start{
  margin-top: -5px;
}
.products{
  margin-bottom: -15px;
  margin-top: 15px;
}
.title{
  color: #222222;
  font-weight: bold;
}
.footer{
  margin-top: 140px;
}
.navbar-buttom{
  display: none;
}
.footer-nav {
  display: none;
}

/* MY BAG */
.bag-my{
  margin-top: 90px;
}
.card-details {
  padding: 30px;
  border-radius: 11px;
}
.delete{
    color: #DB3022;
}
.checklist{
    margin-left:20px;
}

.customcheck input {
    position: relative;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 10;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 5px;
}

.customcheck:hover input ~ .checkmark,.checkmak {
    background-color: #ccc;
}

.customcheck input:checked ~ .checkmark{
    background-color: #03ac0e;
    border-radius: 5px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.customcheck input:checked ~ .checkmark:after {
    display: block;
}

.customcheck .checkmak:after {
    left: 8px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.img-products{
    border-radius: 3px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.check{
  margin-top: -10px !important;
  position: relative;
}
.check-select{
  margin-top: -3px !important;
  position: relative;
}
.table{border-bottom:none #fff !important;}
.select-all{
  height: 70px;
}

.min{
    border-radius: 50%;
    background-color: #D4D4D4;
    border: 0;
    margin-left: -30px;
}
.min:hover{
    background-color: #aca2a2;
}
.max{
    border-radius: 50%;
    margin-left: 20px;
}
.one{
  margin-left: -500px !important;
}
.btn-checkout{
    background-color: #03ac0e;
    border-radius: 40px;
    color: #fff;
}
.title-shoping{
    font-weight: 600;
font-size: 18px;
line-height: 16px;
color: #222222;
}
.sum-price{
  color: #9B9B9B;
  font-weight: 500;
  line-height: 16px;
}

/* CART */
.my-check{
  margin-top: 90px;
}
.btn-address{
  border-radius: 20px;
  border: 1px solid #9B9B9B;
  width: 50%;
}
.total-price{
  color: #9B9B9B;
  font-weight: 500;
  line-height: 16px;
}
.total-shopping{
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-top: 20px;
}
.post, .sub-post{
  margin-left: -10px;
}
.add-adress{
  height: 100px;
  border: 1px dashed  #949599;
}
.title-add{
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-top: 10px;
}
.address{
  border: 1px solid #03ac0e;
  height: 140px;
}
.address-content{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.address-new, .address-change{
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

/* DETAIL */
.child-page{
  margin-top: 100px;
}
.galleries img{
  border-radius: 5px;
  border: 1px solid #a4a5a7;
}
.rating{
  font-weight: 400;
  font-size: 12px;
  line-height: 8px;
}
.product-title , .title-info{
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
}
.sub-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

/* flex box */
.thumb a img{
  width: 70px;
  height: 70px;
  display: inline;
}


/* grid */
.cover{
  display: flex;
  flex-wrap: wrap;
}

.start{
  margin-top: -8px;
}
.price-detail{
  font-weight: 700;
  font-size: 33px;
  line-height: 33px;
}
.title-price{
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
.title-color, .title-size,.title-jumlah{
    font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
.btn-black, .btn-red, .btn-blue, .btn-green{
  border-radius: 50%;
  width: 36px;
  height: 36px;
}
.btn-black{
  background-color: #222222;
  margin: auto;
}
.btn-red{
  background-color: #D84242;
    margin-left: 3px;
    margin-top: 3px;
}
.btn-blue{
  background-color: #4290D8;
    margin-left: 3px;
    margin-top: 3px;
}
.btn-green{
  background-color:#03ac0e;
    margin-left: 3px;
    margin-top: 3px;
}
.btn-border{
  border: 1px solid#03ac0e;
  border-radius: 50%;
  width: 42px;
  height: 42px;
}
.btn-black:hover{
  background-color: #2e2e2e;
  margin: auto;
}
.btn-red:hover{
  background-color: #d62d2d;
  border: 0;
}
.btn-blue:hover{
  background-color: #2c75b9;
  border: 0;
}
.btn-green:hover{
  background-color: #44f877;
  border: 0;
}
.btn-border:hover{
  border: 1px solid#44f877;
}
.icon{
  margin-left: -2px !important;
}
.btn-min{
  background-color: #a4a5a7;
  border-radius: 50%;
  width: 36px;
  height: 36px;
}
.btn-min:hover{
  background-color: #878d99;
}
.btn-max{
  background-color: #ffffff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
}
.btn-max:hover{
  background-color: aliceblue;
}
.size{
  color: #222222;
}
.title-jumlah{
  margin-left: 125px !important;
}
.btn-chat, .btn-bag, .btn-buy{
  border-radius: 24px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.btn-buy{
  background-color: #03ac0e;
  color: #fff;
}
.btn-bag, .btn-chat{
  background-color: #fff;
  border: 1px solid #222222;
}
.text{
  font-weight: 500;
font-size: 14px;
line-height: 24px;
}
.new{
  margin-top: -5px;
}
.text-sub{
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #222222;;
}
.text-rating{
  font-weight: 500;
  font-size: 60px;
  line-height: 60px;
}
.text-finis{
  font-weight: bold;
  margin-left: -15px;
  font-size: 20px;
  line-height: 20px;
}
.border-rating, .border-null{
  width: 120px;
  height: 5px;
  margin-top: 8px;
}
.garis{
  background-color: #a4a5a7;
  height: 2px;
}
.title-suggestion{
  margin-top: 20px;
  font-weight: bold;
  font-size: 30px;
  line-height: 22px;
  color: #222222;
}
.price-sugation{
  font-weight: 500;
font-size: 16px;
line-height: 16px;
color: #03ac0e;
}
.merk{
  font-weight: 500;
font-size: 12px;
line-height: 12px;
color:#9B9B9B;
}
.card-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width:1400px) {
  .caption{
    font-weight: 700;
    font-size: 4rem;
    line-height: 40px;
    border: #9B9B9B;
  }
}

@media (max-width:1024px) {
  .caption{
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 40px;
    border: #9B9B9B;
  }
}

@media (max-width:768px) {
  .caption{
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 40px;
    border: #9B9B9B;
  }
}

@media (max-width:576px) {

  .slide{
    margin-top: 10px;
  }
  .nav-search-buttom{
    margin: auto;
    width: 300px;
    margin-bottom: 5px;
  }
  .search-input-nav{
  border-radius: 50px;
  border-right: 0px solid rgb(255, 255, 255);
}
  .filter img{
    margin-left: -5px;
  }
  .filter{
    float: right;
    margin: 5px;
    width: 25%;
    margin-left: -70px !important;
  }
  .caption{
    font-size: 30px;
  }
  .font-category{
    font-size: 28px;
  }
  .footer-dekstop{
    display: none;
  }
  .footer-nav {
  display: inline-block;
  width: 100%;
  }
  .navbar{
    display:none;
  }
  .navbar-buttom{
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  /* MY BAG */
  .nav-search{
    margin: 20px 0px 3px -80px;
    width: 300px;
  }
  .card-details{
      margin-bottom: 100px;
  }
  .post{
      font-size: 10px;
  }
  .sub-post{
      font-size: 5px;
      margin-top: -100px !important;
  }
  .min,.max{
      width: 10px;
      height: 20px;
      border-radius: 50%;
  }
  .icon-max{
      position: static;
      margin: -17px 0 0 -6px;
  }
  .icon-min{
      position: static;
      margin: -17px 0 0 -7px;
  }
  .price{
      font-size: 10px;
  }

  /* MY CART */
    .my-check{
    margin-top: 10px;
  }
    .post{
      font-size: 10px;
  }
  .sub-post{
      font-size: 5px;
      margin-top: -100px !important;
  }
  .btn-address{
    width: 100%;
  }
  .address{
    height: 200px;
  }
  .address-content{
    line-height: 20px;
  }

  /* MY DETAIL */
    .thumb a img{
    justify-content: center;
    margin: 5px auto
  }
}