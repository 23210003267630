body{
    overflow-x: hidden;
}

@font-face /*perintah untuk memanggil font eksternal*/
{
    font-family: 'metropolis'; /*memberikan nama bebas untuk font*/
    src: url('../../components/module/home//metropolis.regular.otf');/*memanggil file font eksternalnya di folder nexa*/
}
.title-login{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    /* font-family:monospace */
}
.nav-login {
    margin: 0 18px 0 18px;
}
.customer, .seller {
    border: 1px solid #9B9B9B;
    border-radius: 4px 0px 0px 4px;
    background-color: #fff;
    /* margin-left: 10px; */
    color: #9B9B9B;
}
.seller-login, .customer-login{
    background-color: #4fd15a;
    border-radius: 0 !important;
    color: #fff;
}
.seller-login{
    border-radius: 0px 5px 5px 0px;
}
.customer-on{
    background-color: #03ac0e; 
    color: #fff;
}
.customer-on:hover{
    background-color: #4fd15a ;
    color: #fff;
    border: 1px solid #9B9B9B;
}
.customer-off{
    background-color: #fafafa; 
    color: rgb(0, 0, 0);
    border: 1px solid #9B9B9B;
}
.customer-off:hover{
    background-color: #ffffff ;
    color: rgb(0, 0, 0);
    border: 1px solid #9B9B9B;
}
.seller-on {
    border: 1px solid #9B9B9B;
    border-radius: 0px 4px 4px 0px;
    background-color:#03ac0e; 
    color: #ffffff;
}
.seller-on:hover{
    background-color: #4fd15a ;
    color: #fff;
    border: 1px solid #9B9B9B;
    border-radius: 0 5px 5px 0;
}
.seller-off {
    border: 1px solid #9B9B9B;
    border-radius: 0px 4px 4px 0px;
    background-color: #fff; 
    color: rgb(20, 20, 20);
}
.seller-off:hover {
    border: 1px solid #9B9B9B;
    border-radius: 0px 4px 4px 0px;
    background-color: #fff; 
    color: rgb(22, 20, 20);
}
/* ::-webkit-input-placeholder { 
    font-size: 100px;
} */
.header-login{
    padding: 0 15px 15px 15px;
    text-align: center;
}
.form-signin {
    width: 100%;
    max-width: 400px;
    padding: 5px;
    margin-top: 50px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.btn-sign{
    background-color: #03ac0e;
    border-radius: 25px;
    color: #fff;
}
.btn-sign:hover{
    background-color: #4fd15a;
    color: #fff;
}
.float-end{
    color: #4fd15a;
}
.form-email, .form-password{
    height: 48px !important;
}
.register{
    color: #222222;
    text-align: center;
}
.page-register,.page-login{
    color: #4fd15a;
    margin-left: 4px;
    cursor: pointer;
    text-decoration:none;
    text-align: center;
}