.edit-profil{
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #9B9B9B;
    margin-top: -8px;
}

.text-profil{
  font-weight: 600;
}

.select-profil{
    
    background-color: #fff;
    height: auto !important;
    /* margin-top: -66px !important; */
}

.profil-avatar,.profil-select{
  margin-left: 120px;
}

.profil-form{
  margin-top: 66px;
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex:1;
  justify-content: center;
}

.image,.image-text{
  margin-top: 70px;
}

.title-dashboard{
  font-weight: 800;
  font-size: 15px;
  line-height: 24px;
  margin-top: 5x;
}

@media (max-width: 576px) {

  .search-nav{
    margin-left: -80px;
    margin-top: 10px;
  }

  .filter{
    float: right;
    margin: 5px;
    width: 25%;
    margin-left: -70px !important;
  }

  .profil-avatar, .profil-select{
    margin-left: 10px !important;
    margin-top: 40px;
  }

  .photo-form{
      border: 1px dotted #D4D4D4;
      border-radius: 4px;
      height: 1500px;
  }

  .upload{
      margin-top: 22px !important;
  }

  /* .select-profil{
      height: 480px !important;
      margin-top: 5px !important;
  } */

  .image,.image-text{
    margin-top: 5px;
  }

  .profil-form{
    /* margin-left: 2px; */
    /* margin-top: -15px !important; */
  }
}