.categories-card {
    height: 250px;
    object-fit: cover !important;
}

div img.img-fluid.categories-card{
    border-radius: 10px 10px 0 0 !important;
}

.card {
    border-radius: 10px !important;
}

.card-shadow {
    border-radius: 10px !important;
    box-shadow: none;
}

.card-shadow:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}