body, html{
    overflow-x: hidden !important;
}

.caption{
    font-size: 5.25rem;
    margin-top: 275px;
}

.slide-size{
    height: 450px;
    object-fit: cover;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
    /* border-color: rgba(126, 239, 104, 0.8); */
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6); */
    border-color: #e4d8d8;
    box-shadow: none;
    outline: 0 none;
    /* display: none; */
}

.dropdown-toggle:focus {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    outline: 0 none;
}

@media screen and (max-width : 768px) {
    .caption{
        font-size: 2.3rem;
        text-align: center;
        margin-top: 175px ;  
    }

    .slide-size{
        height: 200px;
        object-fit: cover;
    }
}